import React, { FC, ReactNode } from "react";
import "./styles.css";

interface IProps {
  label: string;
  icon: ReactNode;
  onClick?: Function;
  id?: string;
  className?: string;
  active?: boolean;
}
const PackageServiceCard : FC<IProps> = ({
  label,
  icon,
  onClick,
  id,
  active,
  className
}) => (
  <div
    id={id || ""}
    className={["package-service-card", ...(active ? ["active"] : [""]), ...(className ? [className] : [""])].join(" ")}
    {...(onClick ? {onClick:onClick} : {})}
  >
    {icon && icon}
    {label && <p>{label}</p>}
  </div>
);

export default PackageServiceCard;
