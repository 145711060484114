import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getWorkshopFinder } from "../../stores";
import { CkButton, CkIcon } from "../../CkUI/components";
import SortInput from "./SortInput";
import FiltersInput from "./FiltersInput";
import LocationInput, { currentLocationObject } from "./LocationInput";
import ServiceInput, { translatePathToIcon } from "./ServiceInput";
import {
  storeFetchServicesCarousel,
  storeFetchPackagesCombination,
} from "../../stores/Slice/WorkshopFinderSlice";
import "./styles.css";

export type TFinderDropdown =
  | undefined
  | "location"
  | "service"
  | "filters"
  | "filters-resume"
  | "sort"
  | "sort-resume";
export interface IFinderSection {
  displayDropdown: boolean;
  setDisplayDropdown: (newValue: TFinderDropdown) => void;
}
export interface WorkshopFinderHandles {
  collapse: () => void;
  expand: () => void;
}
interface IProps {
  onSearchCallback: Function; // collapsable on mobile
  goBack?: boolean | Function;
  collapsable?: boolean;
  showSort?: boolean;
  hideButton?: boolean;
  title?: string;
  className?: string;
  id?: string;
}
const WorkshopFinder: ForwardRefRenderFunction<
  WorkshopFinderHandles,
  IProps
> = (
  {
    onSearchCallback,
    goBack = false,
    collapsable = false,
    showSort = false,
    hideButton = false,
    title,
    className,
    id,
  },
  ref
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const workshopFinder = useSelector(getWorkshopFinder);
  const [validSettings, setValidSettings] = useState<boolean>();
  const [displayDropdown, setDisplayDropdown] = useState<TFinderDropdown>();
  const [collapsed, setCollapsed] = useState<boolean>(
    collapsable ? true : false
  );
  const [locationResume, setLocationResume] = useState<{
    icon: string;
    label: string;
  }>();
  const [serviceResume, setServiceResume] = useState<{
    icon: string;
    label: string;
  }>();
  const nowChanging = useRef<{
    field: TFinderDropdown;
    value: string;
  }>({
    field: undefined,
    value: "",
  });

  /**
   * Functions
   */
  const search = () => {
    if ((!hideButton && validSettings === false) || validSettings === undefined)
      return;
    onSearchCallback();
  };

  const goBackCallback = () => {
    if (!!goBack === false) return;
    if (goBack === true) {
      history.goBack();
    }
    if (typeof goBack === "function") {
      goBack();
    }
  };

  const validateFinder = (): boolean => {
    const { criteria, selected, location } = workshopFinder;
    let valid = true;
    // Validate packages or services
    if (criteria !== "REP" && criteria !== "MAN") {
      valid = false;
      setServiceResume({
        icon: "package-idk",
        label: "Mantenimiento o reparación no seleccionado",
      });
    }

    if (criteria === "MAN" && selected.packages === undefined) {
      valid = false;
      setServiceResume({
        icon: "package-idk",
        label: "Mantenimiento no seleccionado",
      });
    }
    if (criteria === "MAN" && selected.packages !== undefined) {
      setServiceResume({
        icon: translatePathToIcon(selected?.packages?.iconPath, "package", () =>
          dispatch(storeFetchPackagesCombination())
        ),
        label: selected.packages.servicePackageDesc,
      });
    }

    if (criteria === "REP" && selected.services === undefined) {
      valid = false;
      setServiceResume({
        icon: "service-idk",
        label: "Tipo de reparación no seleccionada",
      });
    }
    if (criteria === "REP" && selected.services !== undefined) {
      setServiceResume({
        icon: translatePathToIcon(
          selected.services.iconPath,
          "service",
          () => {} // dispatch(storeFetchServicesCarousel())
        ),
        label: selected.services.serviceTypeName,
      });
    }
    // Validate location
    if (location.use === undefined) {
      valid = false;
      setLocationResume({
        icon: "location",
        label: "Ubicación no seleccionada",
      });
    }

    if (location.use === "current") {
      setLocationResume(currentLocationObject);
    }
    if (
      location.use === "selected" &&
      (location.searchCoordinates.latitude === undefined ||
        location.searchCoordinates.longitude === undefined)
    ) {
      valid = false;
      setLocationResume({
        icon: "location",
        label: "Ubicación no valida",
      });
    }
    if (
      location.use === "selected" &&
      (location.searchCoordinates.latitude !== undefined ||
        location.searchCoordinates.longitude !== undefined)
    ) {
      setLocationResume({
        icon: "location",
        label: location.formatted_address,
      });
    }
    return valid;
  };

  /**
   * Effects
   */
  useEffect(() => {
    collapsable && collapsed && validSettings === false && setCollapsed(false);
  }, [validSettings, collapsed, collapsable]);

  useEffect(() => {
    if (displayDropdown === undefined) {
      if (
        (nowChanging.current.field === "sort-resume" &&
          nowChanging.current.value !== workshopFinder.sort) ||
        nowChanging.current.field !== "sort-resume"
      ) {
        hideButton
          ? search()
          : collapsable && collapsed && validSettings && search();
      }
      nowChanging.current = {
        field: undefined,
        value: "",
      };
    } else {
      nowChanging.current.field = displayDropdown;
      if (displayDropdown === "sort-resume")
        nowChanging.current.value = workshopFinder.sort;
    }
  }, [displayDropdown]);

  useEffect(() => {
    const isValid = validateFinder();
    if (isValid !== validSettings) setValidSettings(isValid);
  }, [workshopFinder]);

  useImperativeHandle(
    ref,
    () => {
      return {
        collapse: () => {
          collapsable && setCollapsed(true);
        },
        expand: () => {
          collapsable && setCollapsed(false);
        },
      };
    },
    []
  );

  /**
   * components
   */
  return (
    <section
      className={[
        "workshop-finder",
        ...(className ? [className] : []),
        ...(showSort ? ["with-sort"] : []),
        ...(collapsable ? ["collapsable"] : []),
        ...(collapsed ? ["collapsed"] : []),
        ...(goBack ? ["returnable"] : []),
      ].join(" ")}
      {...(id ? { id } : {})}
    >
      {goBack && (
        <CkButton
          className="go-back-button"
          color="white"
          variant="text"
          icon={<ArrowLeftOutlined />}
          onClick={goBackCallback}
        />
      )}
      {collapsable && (
        <CkButton
          className="collapse-button"
          color="white"
          variant="text"
          icon={<CkIcon name={collapsed ? "pencil" : "close"} width="" height="" fill="" />}
          onClick={() => setCollapsed((prevState) => !prevState)}
        />
      )}
      {title && <h1>{title}</h1>}
      <div className="finder-container">
        <div className="inputs-group">
          <LocationInput
            displayDropdown={displayDropdown === "location"}
            setDisplayDropdown={setDisplayDropdown}
          />
          <ServiceInput
            displayDropdown={displayDropdown === "service"}
            setDisplayDropdown={setDisplayDropdown}
          />
        </div>
        <div className="buttons-group">
          <FiltersInput
            displayDropdown={displayDropdown === "filters"}
            dropdownName="filters"
            color="white"
            setDisplayDropdown={setDisplayDropdown}
          />
          {showSort && (
            <SortInput
              displayDropdown={displayDropdown === "sort"}
              dropdownName="sort"
              color="white"
              setDisplayDropdown={setDisplayDropdown}
            />
          )}
          {!hideButton && (
            <CkButton
              color="secondary"
              onClick={() => {
                if (!validSettings) history.push("/mapView");
                else search();
              }}
            >
              Buscar talleres cercanos
            </CkButton>
          )}
        </div>
      </div>

      {collapsable && (
        <div className="finder-resume">
          <div className="resume">
            {locationResume && (
              <div className="resume-item">
                <CkIcon name={locationResume.icon} width="" height="" fill="" />
                <p>{locationResume.label}</p>
              </div>
            )}
            {serviceResume && (
              <div className="resume-item">
                <CkIcon name={serviceResume.icon} width="" height="" fill="" />
                <p>{serviceResume.label}</p>
              </div>
            )}
          </div>
          <div className="actions">
            <FiltersInput
              displayDropdown={displayDropdown === "filters-resume"}
              dropdownName="filters-resume"
              color="primary"
              setDisplayDropdown={setDisplayDropdown}
            />
            {showSort && (
              <SortInput
                displayDropdown={displayDropdown === "sort-resume"}
                dropdownName="sort-resume"
                color="primary"
                setDisplayDropdown={setDisplayDropdown}
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default forwardRef(WorkshopFinder);
