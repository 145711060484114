import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const metadata = {
  default: {
    title:
      "CarKer: Encuentra Talleres Automotrices en CDMX, Guadalajara y EDOMEX",
    description:
      "Con CarKer, encuentra talleres automotrices verificados para reparación y mantenimiento de tu auto en CDMX, Guadalajara y EDOMEX. ¡Agenda tu servicio hoy!",
  },
  commitments: {
    title: "Calidad Asegurada: Talleres Verificados CarKer",
    description:
      "Descubre los talleres afiliados a CarKer, seleccionados por su excelencia y compromiso con la calidad. Confía en los expertos que valoran tu seguridad y satisfacción.",
  },
  about: {
    title: "Sobre Nosotros: Conectamos con los Mejores Talleres - CarKer",
    description:
      "Descubre cómo CarKer conecta conductores con los mejores talleres mecánicos en México para un servicio confiable y de alta calidad.",
  },
  faq: {
    title: "Preguntas Frecuentes - Soporte CarKer",
    description:
      "¿Tienes dudas? Consulta las preguntas frecuentes y obtén soporte sobre los servicios de mantenimiento y reparación de autos con CarKer.",
  },
  contact: {
    title: "Contacta a CarKer: Estamos para Ayudarte",
    description:
      "¿Necesitas ayuda o tienes alguna sugerencia? Contacta al equipo de CarKer para obtener soporte rápido y eficiente.",
  },
  blog: {
    title: "Blog Automotriz CarKer",
    description:
      "Mantente informado con consejos, noticias y tendencias del mundo automotriz en nuestro blog CarKer. Mejora el cuidado de tu vehículo con nuestros expertos.",
  },
  terms: {
    title: "Términos y Condiciones - CarKer",
    description:
      "Lee los términos y condiciones de CarKer para entender mejor los servicios de mantenimiento y reparación automotriz que ofrecemos.",
  },
};

interface IProps {
  page?: string;
}

const Metadata: FC<IProps> = ({ page }) => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    if (page && metadata[page].title) setTitle(metadata[page].title);
    else setTitle(metadata["default"].title);

    if (page && metadata[page].description)
      setDescription(metadata[page].description);
    else setDescription(metadata["default"].description);
  }, [page]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} data-react-helmet="true" />
    </Helmet>
  );
};

export default Metadata;
