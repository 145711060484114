import React, { FC, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getFinderServicesCatalog,
  getFinderSelectedServices,
} from "../../../../stores/selectors";
import {
  setSelectedService,
} from "../../../../stores/Slice/WorkshopFinderSlice";
import { gtmSetId } from "../../../../utilities";
import { IServiceCategory } from "../../../../models";
import PackageServiceCard from "../PackageServiceCard";
import { CkButton, CkIcon, CkIconNames } from "../../../../CkUI/components";
import { translatePathToIcon } from "..";
import "./styles.css";

const idkService: IServiceCategory = {
  typePosition: -1,
  serviceTypeCode: "IDK",
  serviceTypeName: "No sé qué elegir",
  iconPath: "idk",
  longDescTitle: "string",
  longDesc: "string",
};

const ServiceCard = memo(
  ({
    service,
    afterChoose,
  }: {
    service: IServiceCategory;
    afterChoose: Function;
  }) => {
    const dispatch = useDispatch();
    const selectedServices = useSelector(getFinderSelectedServices);
    const onClickHandler = () => {
      dispatch(setSelectedService(service));
    };
    return (
      <PackageServiceCard
        {...(service?.htmlId ? { id: service.htmlId } : {})}
        onClick={(e) => {
          onClickHandler();
          gtmSetId(e.currentTarget);
          afterChoose();
        }}
        label={service.serviceTypeName}
        icon={
          <CkIcon
            width=""
            height=""
            fill=""
            name={
              translatePathToIcon(
                service.iconPath,
                "service",
                () => {}
              ) as CkIconNames
            }
          />
        }
        active={
          selectedServices &&
          selectedServices.serviceTypeCode === service.serviceTypeCode
        }
      />
    );
  }
);

interface IProps {
  afterChoose: Function;
}
const ServicesSelection: FC<IProps> = ({ afterChoose }) => {
  const serviceCategories: { values: IServiceCategory[] } = useSelector(
    getFinderServicesCatalog
  );
  const dispatch = useDispatch();

  return (
    <>
      <div className="package-service-cards-container">
        {serviceCategories.values.map(
          (service: IServiceCategory) =>
            service && (
              <ServiceCard
                service={service}
                afterChoose={afterChoose}
                key={`service-${service.serviceTypeCode}`}
              />
            )
        )}
      </div>
      <CkButton
        className="idk-service"
        icon={
          translatePathToIcon(
            idkService.iconPath,
            "service",
            () => {}
          ) as CkIconNames
        }
        onClick={() => {
          dispatch(setSelectedService(idkService));
          afterChoose && afterChoose();
        }}
        type="link"
      >
        {idkService.serviceTypeName}
      </CkButton>
    </>
  );
};

export default memo(ServicesSelection);
