//React & styles
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { CkButton, CkIcon, CkRate } from "../../../CkUI";
import { useDispatch } from "react-redux";
//Interfaces
import { getImageBaseUrl } from "../../../utilities";
import { IWorkshop } from "../../../models";

import Specialization from "../../../screens/Workshops/WorkshopDetails/WorkshopHeader/Specialization";
import WatermarkImage from "../../../screens/Workshops/WatermarkImage";
import WorkshopAdditional from "../../../screens/Workshops/WorkshopDetails/WorkshopAdditional";

import "./style.css";
import { openContactDrawer } from "../../../stores";

const cupPromotions = getImageBaseUrl("cup-promotions.png");
interface IPackagedWorkshop extends IWorkshop {
  packagePrice?: number;
  hasWarranty?: boolean;
}
interface IProps {
  history: any;
  sasToken: string;
  workshop: IPackagedWorkshop;
  forceHeight?: number;
}

const WorkshopLink: FC<{
  onClick?: Function;
  className?: string;
  children: any;
  workshop: IPackagedWorkshop;
}> = ({ workshop, onClick, className, children, ...rest }) => (
  <Link
    to={`/workshop/${workshop.name
      .replace(/[^a-zA-Z ]/g, "")
      .replaceAll(" ", "_")}/${workshop.id}`}
    className={`workshop-link ${className || ""}`}
    onClick={(e) => onClick && onClick()}
    {...rest}
    aria-label={`Ver perfil del taller ${workshop.name}`}
    title={workshop.name}
  >
    {children}
  </Link>
);

const OutstandingWorkshopCard: React.FC<IProps> = ({
  history,
  sasToken,
  workshop,
  forceHeight,
}) => {
  const dispatch = useDispatch();
  return (
    <div
      className={`outstanding-workshop-card`}
      {...(forceHeight ? { style: { minHeight: forceHeight } } : {})}
    >
      {workshop.hasPromotion && (
        <div className="promo-badge">
          <img src={cupPromotions} width={26} height={26} alt="" />
        </div>
      )}
      <figure>
        <WorkshopLink workshop={workshop}>
          <WatermarkImage
            sasToken={sasToken}
            img={{
              name: "logo",
              description: "logo",
              photoUrl: workshop.photoUrl,
            }}
            alt={workshop.name}
          />
        </WorkshopLink>
      </figure>
      <figcaption>
        <WorkshopLink workshop={workshop}>{workshop.name}</WorkshopLink>
      </figcaption>
      <div className="badge-container">
        {(workshop?.location?.cityZone ||
          workshop?.location?.municipality === "Guadalajara") && (
          <div className="distance-badge">
            <CkIcon name="location-filled" width="" height="" fill="" />
            <p>
              {workshop?.location?.cityZone === ""
                ? "Guadalajara"
                : workshop?.location?.cityZone}
            </p>
          </div>
        )}
        <div
          className={`is-open-badge ${
            workshop.isOpen ? "is-open" : "is-closed"
          }`}
        >
          <p>{workshop.isOpen ? "Abierto" : "Cerrado"}</p>
        </div>
      </div>
      <div className="rating-display">
        <CkRate disabled defaultValue={workshop.rating} />
        <p>
          {!workshop?.numberOfFeedbacks || workshop?.numberOfFeedbacks <= 0
            ? "Sin calificaciones"
            : `(${workshop?.numberOfFeedbacks})`}
        </p>
      </div>
      <Specialization
        workshopBrands={workshop?.workshopBrands}
        isWorkshopList
      />
      <WorkshopAdditional
        workshopAdditionalInfo={workshop.workshopAdditionalInfo}
        isMobile={true}
      />
      <div className="button-container">
        <CkButton
          variant="secondary"
          className="standard-white-btn"
          onClick={() => dispatch(openContactDrawer(workshop.id))}
          icon="chat-variant"
        >
          Contactar al taller
        </CkButton>
        <CkButton
          onClick={() =>
            history.push(`/taller/${workshop.id}/agendar?from=wsList`)
          }
          icon="calendar-variant"
        >
          Agendar una cita
        </CkButton>
      </div>
    </div>
  );
};

export default OutstandingWorkshopCard;
