import React, { FC, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPackage } from "../../../../stores/Slice/WorkshopFinderSlice";
import { IPackageServiceCombinations } from "../../../../models";
import {
  getFinderPackagesCatalog,
  getFinderSelectedPackage,
} from "../../../../stores/selectors";

import { gtmSetId } from "../../../../utilities";
import { CkIcon, CkIconNames, CkButton } from "../../../../CkUI/components";
import PackageServiceCard from "../PackageServiceCard";
import { translatePathToIcon } from "..";

import "./styles.css";

const idkPackage: IPackageServiceCombinations = {
  servicePackageCode: "IDK",
  servicePackageDesc: "No sé qué elegir",
  iconPath: "idk",
  types: [
    {
      servicePackageTypeCode: "IDK",
      servicePackageTypeDesc: "No sé qué elegir",
    },
  ],
};

const PackageCard = memo(
  ({
    packages,
    afterChoose,
  }: {
    packages: IPackageServiceCombinations;
    afterChoose: Function;
  }) => {
    const dispatch = useDispatch();
    const selectedPackages: IPackageServiceCombinations = useSelector(
      getFinderSelectedPackage
    );
    const onClickHandler = () => {
      if (selectedPackages === null || selectedPackages === undefined) {
        dispatch(
          setSelectedPackage({ ...packages, searchType: packages.types[0] })
        );
        return;
      }
      if (selectedPackages.servicePackageCode !== packages.servicePackageCode) {
        dispatch(
          setSelectedPackage({ ...packages, searchType: packages.types[0] })
        );
        return;
      }
    };

    return (
      <PackageServiceCard
        id={packages.htmlID}
        onClick={(e) => {
          onClickHandler();
          gtmSetId(e.currentTarget);
          afterChoose();
        }}
        label={packages.servicePackageDesc}
        icon={
          <CkIcon name={translatePathToIcon(packages.iconPath, "package", () => {}) as CkIconNames} width="" height="" fill="" />
        }
        active={
          selectedPackages?.servicePackageCode === packages.servicePackageCode
        }
      />
    );
  }
);

interface IProps {
  afterChoose: Function;
}
const PackagesSelection: FC<IProps> = ({ afterChoose }) => {
  const packages = useSelector(getFinderPackagesCatalog);
  const dispatch = useDispatch();
  const selectedPackages: IPackageServiceCombinations = useSelector(
    getFinderSelectedPackage
  );

  return (
    <>
      <div className="package-service-cards-container">
        {packages.values.map((pack: IPackageServiceCombinations) => (
          <PackageCard
            packages={pack}
            afterChoose={afterChoose}
            key={`service-${pack.servicePackageCode}`}
          />
        ))}
      </div>
      <CkButton
        className="idk-service"
        icon={
          translatePathToIcon(
            idkPackage.iconPath,
            "package",
            () => {}
          ) as CkIconNames
        }
        onClick={() => {
          if (selectedPackages === null || selectedPackages === undefined) {
            dispatch(
              setSelectedPackage({ ...idkPackage, searchType: idkPackage.types[0] })
            );
            return;
          }
          if (
            selectedPackages.servicePackageCode !== idkPackage.servicePackageCode
          ) {
            dispatch(
              setSelectedPackage({ ...idkPackage, searchType: idkPackage.types[0] })
            );
            return;
          }
          afterChoose && afterChoose;
        }}
        type="link"
      >
        {idkPackage.servicePackageDesc}
      </CkButton>
    </>
  );
};

export default memo(PackagesSelection);
