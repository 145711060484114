import React, { useEffect, useState, useRef, FC, memo } from "react";
import { Popover, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { CkModal, CkInput, CkIcon, CkSwitchText } from "../../../CkUI/components";
import { useIsMobileScreen, localStorage } from "../../../utilities";
import { getWorkshopFinder, setSelectedCriteria, getFinderPackagesCatalog, getFinderServicesCatalog } from "../../../stores";
import {
  storeFetchServicesCarousel,
  storeFetchPackagesCombination
} from "../../../stores/Slice/WorkshopFinderSlice"
import { WorkshopServiceType } from "../../../models";

import PackageTiersModal from "./PackageTiersModal";
import PackagesSelection from "./PackagesSelection";
import ServicesSelection from "./ServicesSelection";
import { IFinderSection } from "..";
import "./styles.css";

export const translatePathToIcon = (path:string, prefix: "package" | "service", onErrorCallback: Function) :string => {
  try {
    return `${prefix}-${path.replace(".png", "")}`
  } catch (e) {
    console.error("e");
    if (!!path === false) {
      onErrorCallback();
    }
    return "";
  }
  
}

interface IProps extends IFinderSection {}
const ServiceInput: FC<IProps> = ({
  displayDropdown,
  setDisplayDropdown
}) => {
  const dispatch = useDispatch();
  const packages = useSelector(getFinderPackagesCatalog);
  const services = useSelector(getFinderServicesCatalog);
  const isMobile = useIsMobileScreen(992);
  const [selectedValue, setSelectedValue] = useState<string>();
  const [selectedIcon, setSelectedIcon] = useState<string>();
  const storedWorkshopFinder = useRef(new localStorage("workshopFinder"));
  const WorkshopFinderState = useSelector(getWorkshopFinder);
  const [searchBy, setSearchBy] = useState<WorkshopServiceType>(
    storedWorkshopFinder.current.get().criteria,
  );
  const [searchBySwitch, setSearchBySwitch] = useState<boolean>(
    storedWorkshopFinder.current.get().criteria === "REP" ? true : false,
  );
  const inputRef = useRef<HTMLDivElement>(null);
  const popoverContentRef = useRef<HTMLDivElement>(null);

  /**
   * Functions
   */

  const handleSearchByChange = (value: boolean) => {
    const criteria = value ? "REP" : "MAN";
    setSearchBySwitch(value);
    setSearchBy(criteria);
    dispatch(setSelectedCriteria(criteria));
  };
  
  const closeDrowpdown = () => setDisplayDropdown(undefined);

  const handleClickOutsideRefs = (event) => {
    if (event.target.classList.contains("react-switch") || event.target.classList.contains("react-switch-bg")) return;
    if (!!popoverContentRef.current === false || !!inputRef.current === false) {
      closeDrowpdown();
      return;
    }
    if (popoverContentRef.current.contains(event.target) === false && inputRef.current.contains(event.target) === false) {
      closeDrowpdown();
      return;
    }
  };

  /**
   * Effects
   */
  useEffect(() => {
    if (packages.values.length === 0 && packages.status === "idle") {
      dispatch(storeFetchPackagesCombination());
    } else if (packages.values[0].htmlID === undefined) {
      // dispatch(storeFetchPackagesCombination());
    }

    if (services.values.length === 0 && services.status === "idle") {
      dispatch(storeFetchServicesCarousel());
    } else {
      /* if (services?.values[0]?.htmlID === undefined)
        dispatch(storeFetchServicesCarousel()); */
    }
  }, []);

  useEffect(() => {
    if (WorkshopFinderState?.criteria === "MAN") {
      if (!!WorkshopFinderState?.selected?.packages) {
        const pack = WorkshopFinderState?.selected?.packages;
        setSelectedValue(pack?.servicePackageDesc);
        setSelectedIcon(translatePathToIcon(pack?.iconPath, "package", () => dispatch(storeFetchPackagesCombination())));
      } else {
        setSelectedValue(undefined);
        setSelectedIcon(undefined);
      }
    } else {
      if(WorkshopFinderState?.selected?.services) {
        const service = WorkshopFinderState?.selected?.services;
        setSelectedValue(service?.serviceTypeName);
        // setSelectedIcon(translatePathToIcon(service?.iconPath, "service", null))
        setSelectedIcon(translatePathToIcon(service?.iconPath, "service", () => {} /* dispatch(storeFetchServicesCarousel() )*/));
      } else {
        setSelectedValue(undefined);
        setSelectedIcon(undefined);
      }
    }
  }, [WorkshopFinderState]);

  useEffect(() => {
    // Register click event on mounting
    displayDropdown && window.addEventListener("click", handleClickOutsideRefs);
    !displayDropdown && window.removeEventListener("click", handleClickOutsideRefs);
    // Unregister click event on unmounting to avoid memory leaks
    return () => {
      window.removeEventListener("click", handleClickOutsideRefs);
    }
  }, [displayDropdown]);

  /**
   * Components
   */
  const Input = () => (
    <div className="service-input-container" ref={inputRef}>
      <CkInput 
        variant="rounded"
        className={[WorkshopFinderState.criteria, ...(displayDropdown ? ["active"] : [])].join(" ")}
        inputProps={{
          suffix: <CkIcon name="vehicle" width="" height="" fill="" />,
          placeholder: "Elige el tipo de servicio",
          "aria-label": "Elige el tipo de servicio",
          value: selectedValue,
          onKeyDown: (e) => e.preventDefault(), // Prevent user for typing
          onFocus: () => setDisplayDropdown("service"),
          autoFocus: false,
          ...(selectedIcon ? {prefix: <CkIcon name={selectedIcon} width="" height="" fill="" /> } : {})
        }}
      />
    </div>
    
  );

  const HelperPopover = () => (
    <div className="helper-popover-content">
      <p><span className="semibold">Mantenimiento</span> Consiste en servicios estandarizados y con un precio definido, que ayudan a mantener tu vehículo como nuevo.</p>
      <p><span className="semibold">Reparación</span> Son las correcciones de una falla o problema específico en tu vehículo, para volverlo a dejar como nuevo. ¡Agenda una cita para que diagnostiquen y coticen tu reparación!</p>
    </div>
  );

  const Contents = () => (
    <div className="finder-choose-service" ref={popoverContentRef}>
      <p className="title">¿Qué tipo de servicio estás buscando?</p>
      <div className="switch-container">
        <CkSwitchText
          name="searchBySwitch"
          id={searchBySwitch ? "HOME002" : "HOME001"}
          className={[
            "search-by-switch",
            ...(searchBySwitch ? ["checked"] : [""]),
          ].join(" ")}
          leftText="Mantenimiento"
          rightText="Reparación"
          onChange={handleSearchByChange}
          checked={searchBySwitch}
          aria-label="¿Qué tipo de servicio estás buscando?"
        />
        <Popover
          content={<HelperPopover />}
          overlayClassName="helper-popover"
          getTooltipContainer={(e) => e}
          placement="bottom"
          arrowPointAtCenter={false}
          trigger={isMobile ? "click" : "hover"}
        >
          <Button
            className="information-button"
            aria-label="¿Que son paquetes y servicios?"
            icon={<CkIcon name="information-round" width="" height="" fill="" />}
            color="primary"
          />
        </Popover>
      </div>
      {searchBy === "MAN" && <PackagesSelection afterChoose={closeDrowpdown} />}
      {searchBy === "REP" && <ServicesSelection afterChoose={closeDrowpdown} />}
    </div>
  )

  /* 
    I know, Input component renders as component here, and then as a function
    Change it, you must no
    A disturbance in the force, you may cause
  */
  if (isMobile) return (
    <>
      <Input /> 
      <CkModal
        open={displayDropdown}
        onCancel={closeDrowpdown}
      >
        <Contents />
      </CkModal>
    </>
  )

  return (
      <Popover
        open={displayDropdown}
        content={<Contents />}
        placement="bottom"
        getPopupContainer={(e) => e.parentNode as HTMLElement}
        overlayClassName="finder-choose-service-popover"
      >
        {Input()}
      </Popover>
  )
}

export default memo(ServiceInput);
