import React, { useState, useEffect, FC } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  CkModal,
  CkButton,
  CkInput,
  CkSelect,
  CkToggleableTags,
  CkInputCounter,
} from "../../../CkUI/components";
import {
  getFinderFilters,
  getFinderVehiclesCatalog,
  getFinderAdditionalInfoCodesCatalog,
  getFinderKm,
} from "../../../stores";
import {
  DEFAULT_FINDER_DISTANCE,
  storeFetchAdditionalInfoCodes,
  clearFinderFilters,
  setFinderFilters,
  storeKmValue,
} from "../../../stores/Slice/WorkshopFinderSlice";

import { IFinderSection, TFinderDropdown } from "..";

import "./styles.css";

interface IProps extends IFinderSection {
  dropdownName: TFinderDropdown;
  color: "white" | "primary";
}
const FiltersInput: FC<IProps> = ({
  dropdownName,
  color,
  displayDropdown,
  setDisplayDropdown,
}) => {
  const dispatch = useDispatch();
  const finderFilters = useSelector(getFinderFilters);
  const finderKm = useSelector(getFinderKm);
  const vehiclesCatalog = useSelector(getFinderVehiclesCatalog);
  const additionalInfoCodesCatalog = useSelector(
    getFinderAdditionalInfoCodesCatalog
  );
  const [selectedDistance, setSelectedDistance] = useState<number>(
    finderKm || DEFAULT_FINDER_DISTANCE
  );
  const [additionalInfo, setAdditionalInfo] = useState<any[]>(null);
  const [selectedAdditionalServices, setSelectedAdditionalServices] = useState<
    string[]
  >(finderFilters?.additionalInfoCodes || []);
  const [selectedBrand, setSelectedBrand] = useState<string>(
    finderFilters?.workshopBrands
  );
  const [workshopName, setWorkshopName] = useState<string>(
    finderFilters?.workshopName
  );

  /**
   * Functions
   */
  const hideModal = () => setDisplayDropdown(undefined);

  const resetFilters = () => {
    setSelectedDistance(DEFAULT_FINDER_DISTANCE);
    setSelectedAdditionalServices([]);
    setSelectedBrand(undefined);
    setWorkshopName(undefined);
    dispatch(storeKmValue(DEFAULT_FINDER_DISTANCE));
    dispatch(clearFinderFilters());
  };
  const applyFilters = () => {
    // Add exception for warranty
    const hasWarranty = selectedAdditionalServices.filter(
      (item) => item === "hasWarranty"
    )[0];
    const filteredAdditionalServices = selectedAdditionalServices.filter(
      (item) => item !== "hasWarranty"
    );

    dispatch(storeKmValue(selectedDistance));
    dispatch(
      setFinderFilters({
        additionalInfoCodes: filteredAdditionalServices,
        workshopBrands: selectedBrand,
        workshopName: workshopName,
        hasWarranty: hasWarranty ? true : null,
      })
    );
    hideModal();
  };

  /**
   * Effects
   */
  useEffect(() => {
    if (displayDropdown) {
      setSelectedDistance(finderKm);

      // Warranty exception
      if (finderFilters.hasWarranty) {
        setSelectedAdditionalServices([
          ...finderFilters.additionalInfoCodes,
          "hasWarranty",
        ]);
      } else {
        setSelectedAdditionalServices(finderFilters.additionalInfoCodes);
      }

      setSelectedBrand(
        finderFilters.workshopBrands === ""
          ? undefined
          : finderFilters.workshopBrands
      );
      setWorkshopName(
        finderFilters.workshopName === ""
          ? undefined
          : finderFilters.workshopName
      );
    }
  }, [displayDropdown]);

  useEffect(() => {
    if (
      additionalInfoCodesCatalog === undefined ||
      (additionalInfoCodesCatalog.values.length === 0 &&
        additionalInfoCodesCatalog.status === "idle")
    ) {
      dispatch(storeFetchAdditionalInfoCodes());
    }
    setAdditionalInfo([
      ...additionalInfoCodesCatalog.values,
      /*
      {
        description: "Talleres con garantías",
        code: "hasWarranty",
        iconName: "certified-check-shield",
      },*/
    ]);
  }, [additionalInfoCodesCatalog]);

  return (
    <>
      <CkButton
        color={color}
        variant="secondary"
        icon={"filters-dashs"}
        onClick={() => setDisplayDropdown(dropdownName)}
      >
        Filtros
      </CkButton>
      <CkModal
        className="workshop-finder-filters-modal"
        open={displayDropdown}
        onCancel={hideModal}
        title="Añadir filtros"
        primaryAction={{
          label: "Aplicar filtros",
          onClick: applyFilters,
        }}
        tertiaryAction={{
          label: "Borrar filtros",
          onClick: resetFilters,
        }}
      >
        <CkInputCounter
          label="Rango de búsqueda:"
          value={selectedDistance}
          setValue={setSelectedDistance}
          inputProps={{
            min: 1,
            max: 999,
          }}
          units="Km"
        />
        <CkInput
          className={displayDropdown ? "active" : ""}
          variant="default"
          label="Buscar por nombre de taller:"
          inputProps={{
            placeholder: "Escribe el nombre del taller que buscas",
            value: workshopName,
            onChange: (e) => setWorkshopName(e.target.value),
          }}
        />
        {vehiclesCatalog && (
          <CkSelect
            variant="default"
            label="Talleres especializados en la marca:"
            inputProps={{
              placeholder: "Selecciona una marca",
              options: vehiclesCatalog.values.map((value) => {
                return {
                  label: value.desc,
                  value: value.code,
                };
              }),
              value: selectedBrand,
              onChange: (e) => setSelectedBrand(e),
            }}
          />
        )}
        {additionalInfo && (
          <CkToggleableTags
            label="Talleres con servicios adicionales:"
            options={additionalInfo.map((option: any) => {
              return {
                label: option.description,
                value: option.code,
                iconName: option?.iconName,
              };
            })}
            selectedTags={selectedAdditionalServices}
            setSelectedTags={setSelectedAdditionalServices}
          />
        )}
      </CkModal>
    </>
  );
};

export default FiltersInput;
