//React & styles
import React from "react";
import { Skeleton } from "antd";

import "./style.css";

const OutstandingWorkshopCardSkeleton = () => (
  <div className={`outstanding-workshop-card-skeleton`}>
    <figure>
      <Skeleton.Image active />
    </figure>
    <figcaption>
      <Skeleton paragraph={false} active />
    </figcaption>
    <div className="badge-container">
      <Skeleton paragraph={false} active />
      <Skeleton paragraph={false} active />
    </div>
    <div className="rating-display">
      <Skeleton paragraph={false} active />
    </div>
    <div className="rating-display">
      <Skeleton paragraph={false} active />
    </div>
    <div className="workshop-additional-container">
      <Skeleton paragraph={false} active />
      <Skeleton paragraph={false} active />
      <Skeleton paragraph={false} active />
      <Skeleton paragraph={false} active />
    </div>
    <div className="button-container">
      <Skeleton paragraph={false} active />
      <Skeleton paragraph={false} active />
    </div>
  </div>
);

export default OutstandingWorkshopCardSkeleton;
