import React, { FC, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popover } from "antd";
import {
  CkButton,
} from "../../../CkUI/components";
import {
  getFinderSort
} from "../../../stores";
import {
  wokrshopFinderSortOptions,
  setSort,
  ISortSelectOptions
} from "../../../stores/Slice/WorkshopFinderSlice";


import { IFinderSection, TFinderDropdown } from "..";

import "./styles.css";

interface IProps extends IFinderSection {
  dropdownName: TFinderDropdown;
  color: "white" | "primary"
}
const FiltersInput: FC<IProps> = ({ dropdownName, color, displayDropdown, setDisplayDropdown }) => {
  const dispatch = useDispatch();
  const finderSort = useSelector(getFinderSort);
  const inputRef = useRef<HTMLDivElement>(null);
  const popoverContentRef = useRef<HTMLDivElement>(null);

  /**
   * Functions
   */
  const onClick = (option: ISortSelectOptions) => {
    dispatch(setSort(option.sortField))
    setDisplayDropdown(undefined);
  }

  const closeDrowpdown = () => setDisplayDropdown(undefined);

  const handleClickOutsideRefs = (event) => {
    if (!!popoverContentRef.current === false || !!inputRef.current === false) {
      closeDrowpdown();
      return;
    }
    if (popoverContentRef.current.contains(event.target) === false && inputRef.current.contains(event.target) === false) {
      closeDrowpdown();
      return;
    }
  };

  /**
   * Effects
   */
  useEffect(() => {
    // Register click event on mounting
    displayDropdown && window.addEventListener("click", handleClickOutsideRefs);
    !displayDropdown && window.removeEventListener("click", handleClickOutsideRefs);
    // Unregister click event on unmounting to avoid memory leaks
    return () => {
      window.removeEventListener("click", handleClickOutsideRefs);
    }
  }, [displayDropdown]);

  /**
   * Components
   */
  const Contents = () => (
    <div className="finder-sort-popover-content" ref={popoverContentRef}>
      {wokrshopFinderSortOptions.map((option) => (
        <div 
          onClick={() => onClick(option)}
          className={["sort-option", ...(option.sortField === finderSort ? ["selected"] : [])].join(" ")}
        >
          <p>{option.label}</p>
        </div>))}
    </div>
  )

  return (
    <Popover
      open={displayDropdown}
      content={<Contents />}
      placement="bottom"
      getPopupContainer={(e) => e.parentNode as HTMLElement}
      overlayClassName="finder-sort-popover"
    >
      <div
        ref={inputRef}
      >
        <CkButton
          color={color}
          variant="secondary"
          icon={"sort-arrows"}
          onClick={() => setDisplayDropdown(dropdownName)}
          
        >
          Orden
        </CkButton>
      </div>
      
    </Popover>
  );
};

export default FiltersInput;
