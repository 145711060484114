import React, { FC, useState, useRef, useEffect, ReactNode } from "react";
import { Popover } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { CkInput, CkIcon } from "../../../CkUI/components";
import { getFinderLocation } from "../../../stores/selectors";
import { gtmSetId, LoadGoogleMaps } from "../../../utilities";

import {
  setLocationToCurrent,
  setNoneLocationToCurrent,
  setLocationToSelected,
} from "../../../stores/Slice/WorkshopFinderSlice";
import { ICoordinates } from "../../../models";

import { IFinderSection } from "..";
import "./styles.css";

export interface IPrediction {
  description: string;
  place_id: string;
  reference: string;
  structured_formatting?: {
    main_text?: string;
    secondary_text?: string;
  };
}

export interface IPlace {
  address?: string;
  formatted_address?: string;
  searchCoordinates?: ICoordinates;
}

export const currentLocationObject = {
  label: "Tu ubicación actual",
  icon: "location-current",
};
interface IProps extends IFinderSection {}
const LocationInput: FC<IProps> = ({ displayDropdown, setDisplayDropdown }) => {
  const dispatch = useDispatch();
  const [mapsReady, setMapsReady] = useState<boolean>(false);
  const autoComplete = useRef<any>(null);
  const placeDetails = useRef<any>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const popoverContentRef = useRef<HTMLDivElement>(null);
  const finderLocation = useSelector(getFinderLocation);
  const [selectedValue, setSelectedValue] = useState<string>();
  const [selectedIcon, setSelectedIcon] = useState<string>();
  const [predictions, setPredictions] = useState<IPrediction[]>([]);
  const [selectedPlace, setSelectedPlace] = useState<IPlace | undefined>();

  /**
   * Functions
   */
  const closeDrowpdown = () => displayDropdown && setDisplayDropdown(undefined);

  /**
   * This function query place details with Google Maps API
   * @param prediction prediction Object used to query place details
   */
  const getPlaceDetails = (prediction: IPrediction) => {
    var request: google.maps.places.PlaceDetailsRequest = {
      placeId: prediction.place_id,
      fields: ["geometry", "formatted_address"],
    };

    placeDetails.current.getDetails(
      request,
      function (place: any, status: any) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          var center = place.geometry.location;
          setSelectedPlace({
            address: prediction?.description,
            formatted_address: place?.formatted_address,
            searchCoordinates: {
              latitude: center?.lat(),
              longitude: center?.lng(),
            },
          });
          dispatch(
            setLocationToSelected({
              address: prediction?.description,
              formatted_address: place?.formatted_address,
              searchCoordinates: {
                latitude: center?.lat(),
                longitude: center?.lng(),
              },
            })
          );
        }
        setPredictions([]);
      }
    );
  };

  // Callback function to add predictions to state
  const predictionCallback = (predictions: any, status: any) => {
    if (status != google.maps.places.PlacesServiceStatus.OK) {
      setPredictions([]);
      return;
    }
    setPredictions(predictions);
  };

  //Set result types on predictions of places
  const getPlacePredictions = (search: any) => {
    autoComplete.current.getPlacePredictions(
      {
        input: search,
        types: ["establishment", "geocode"],
        componentRestrictions: { country: "mx" },
      },
      predictionCallback
    );
  };

  const setAutoComplete = () => {
    autoComplete.current = new google.maps.places.AutocompleteService();
    placeDetails.current = new google.maps.places.PlacesService(
      document.createElement("div")
    );
  };

  const setCurrentLocation = () => {
    setSelectedValue(currentLocationObject.label);
    setSelectedIcon(currentLocationObject.icon);
    dispatch(setLocationToCurrent());
  };

  const setNoneCurrentLocation = () => {
    dispatch(setNoneLocationToCurrent());
  };

  const clearLocation = () => {
    setNoneCurrentLocation();
    setSelectedValue(undefined);
    setSelectedIcon(undefined);
    setSelectedPlace({
      address: undefined,
      formatted_address: undefined,
      searchCoordinates: undefined,
    });
  };

  const handleClickOutsideRefs = (event) => {
    if (!!popoverContentRef.current === false || !!inputRef.current === false) {
      closeDrowpdown();
      return;
    }
    if (
      popoverContentRef.current.contains(event.target) === false &&
      inputRef.current.contains(event.target) === false
    ) {
      closeDrowpdown();
      return;
    }
  };

  /**
   * Effects
   */
  useEffect(() => {
    if (selectedPlace?.address) {
      setSelectedValue(selectedPlace.address);
    }
  }, [selectedPlace]);

  useEffect(() => {
    if (selectedValue === "") clearLocation();
    if (
      selectedValue &&
      selectedValue.length > 2 &&
      finderLocation.use === undefined
    )
      getPlacePredictions(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if (mapsReady) {
      setAutoComplete();
      if (finderLocation.use === "selected") {
        setSelectedPlace(finderLocation);
      }
      if (finderLocation.use === "current") {
        setCurrentLocation();
      }
    }
  }, [mapsReady]);

  useEffect(() => {
    if (displayDropdown) closeDrowpdown();
  }, [finderLocation])

  useEffect(() => {
    // Register click event on mounting
    displayDropdown && window.addEventListener("click", handleClickOutsideRefs);
    !displayDropdown &&
      window.removeEventListener("click", handleClickOutsideRefs);
    // Unregister click event on unmounting to avoid memory leaks
    return () => {
      window.removeEventListener("click", handleClickOutsideRefs);
    };
  }, [displayDropdown]);

  /**
   * Components
   */
  const LocationOption: FC<{
    id?: string;
    onClick: Function;
    label: string;
    icon?: ReactNode;
    optionType: "current" | "prediction";
  }> = ({ id, onClick, label, icon, optionType }) => (
    <div
      {...(id ? { id } : {})}
      className={[
        "location-option",
        optionType,
        ...(label === selectedValue ? ["selected"] : []),
      ].join(" ")}
      onClick={(e) => {
        if (id) {
          gtmSetId(e.currentTarget);
        }
        onClick();
      }}
    >
      {icon && <CkIcon name={currentLocationObject.icon} width="" height="" fill="" />}
      <p>{label}</p>
    </div>
  );

  const Contents = () => (
    <div className="finder-location-popover-content" ref={popoverContentRef}>
      <LocationOption
        id="HOME090"
        label={currentLocationObject.label}
        icon={currentLocationObject.icon}
        optionType={"current"}
        onClick={setCurrentLocation}
      />
      {predictions.length > 0 && (
        <div className="predictions-container">
          {predictions.map((prediction, index) => (
            <LocationOption
              key={`prediction-${index}`}
              label={prediction.description}
              onClick={() => getPlaceDetails(prediction)}
              optionType={"prediction"}
            />
          ))}
        </div>
      )}
    </div>
  );

  return (
    <Popover
      open={displayDropdown}
      content={<Contents />}
      placement="bottom"
      getPopupContainer={(e) => e.parentNode as HTMLElement}
      overlayClassName="finder-location-popover"
    >
      {(finderLocation.use === "selected" || displayDropdown) && <LoadGoogleMaps isLoadedCallback={setMapsReady} />}
      <div className="input-container" ref={inputRef}>
        <CkInput
          className={displayDropdown ? "active" : ""}
          variant="rounded"
          allowClear
          inputProps={{
            className: "finder-location-input",
            suffix: <CkIcon name="location" width="" height="" fill="" />,
            placeholder: "Ingresa la ubicación del servicio",
            "aria-label": "Ingresa la ubicación del servicio",
            value: selectedValue,
            onKeyDown: (e) => {
              if (finderLocation.use !== undefined) {
                e.preventDefault();
                return;
              }
              !displayDropdown && setDisplayDropdown("location");
            },
            onClick: (e) => { console.info("e", e); !displayDropdown && setDisplayDropdown("location")},
            onChange: (e) => setSelectedValue(e.target.value),
            onFocus: (e) => { console.info("e", e); !displayDropdown && setDisplayDropdown("location")},
            ...(selectedIcon ? { prefix: <CkIcon name={selectedIcon} width="" height="" fill="" /> } : {}),
          }}
        />
      </div>
    </Popover>
  );
};

export default LocationInput;
